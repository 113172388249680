import {
  faChartLine,
  faCode,
  faPaintBrush,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
const Service = () => {
  return (
    <section className="services" id="services">
      <div className="max-width">
        <h2 className="title">My Services</h2>
        <div className="serv-content">
          <div className="card">
            <div className="box">
              <FontAwesomeIcon icon={faPaintBrush} />

              <div className="text">Web service</div>
              <p>
                We developed a front-end for general web services such as a
                small business big data center website, a map website, and a
                survey website.
              </p>
            </div>
          </div>
          <div className="card">
            <div className="box">
              <FontAwesomeIcon icon={faChartLine} />
              <div className="text">My interest</div>
              <p>
                Mobile AD, E-commerce, Sales and Conversion, Social proof,
                Retargeting, Youtube video, Upselling and cross-selling
              </p>
            </div>
          </div>
          <div className="card">
            <div className="box">
              <FontAwesomeIcon icon={faCode} />

              <div className="text">Apps Design</div>
              <p>
                We have developed an SDK for publishers, SDK for e-commerce, and
                integrated SDK for Scrollads, a mobile display advertisement
                that creates new inventory when customers scroll.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
export default Service;
