const Skills = () => {
  return (
    <section className="skills" id="skills">
      <div className="max-width">
        <h2 className="title">My skills</h2>
        <div className="skills-content">
          <div className="column left">
            <div className="text">My creative skills & experiences.</div>
            <p>
              I like to think about and implement solutions from a completely
              different point of view for big pain points. I am learning to
              study and work hard to create my own solution and to overcome
              failure through the process.
            </p>
            <a href="#about" className="btn-purple">
              <span>Read more</span>
            </a>
          </div>
          <div className="column right">
            <div className="skillbar" data-percent="90%">
              <div className="info">
                <span>HTML</span>
              </div>
              <div className="count-bar html">
                <div className="count"></div>
              </div>
            </div>
            <div className="skillbar" data-percent="60%">
              <div className="info">
                <span>CSS</span>
              </div>
              <div className="count-bar css">
                <div className="count"></div>
              </div>
            </div>
            <div className="skillbar" data-percent="67%">
              <div className="info">
                <span>JavaScript</span>
              </div>
              <div className="count-bar js">
                <div className="count"></div>
              </div>
            </div>
            <div className="skillbar" data-percent="75%">
              <div className="info">
                <span>PHP</span>
              </div>
              <div className="count-bar php">
                <div className="count"></div>
              </div>
            </div>
            <div className="skillbar" data-percent="70%">
              <div className="info">
                <span>MySQL</span>
              </div>
              <div className="count-bar mysql">
                <div className="count"></div>
              </div>
            </div>
            <div className="skillbar" data-percent="80%">
              <div className="info">
                <span>WordPress</span>
              </div>
              <div className="count-bar wordpress">
                <div className="count"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
export default Skills;
