import About from "./About";
import Contact from "./Contact";
import Footer from "./Footer";
import Hero from "./Hero";
import Navbar from "./Navbar";
import Portfolio from "./Portfolio";
import Service from "./Service";
import Skills from "./Skills";
import Instagram from "./Instagram";
import SocialMedia from "./SocialMedia";
const App = () => {
  return (
    <>
      <Navbar />
      <SocialMedia />
      <Hero />
      <About />
      <Service />
      <Skills />
      <Portfolio />
      <Instagram />
      <Contact />
      <Footer />
    </>
  );
};

export default App;
