const Footer = () => {
  return (
    <footer>
      <span>
        Created By <a href="https://www.anilghatan.com.np">Anil Ghatan</a> |{" "}
        <span className="far fa-copyright"></span> 2021 All rights reserved.
      </span>
    </footer>
  );
};
export default Footer;
