import TypedText from "./components/TypedText";

const Hero = () => {
  return (
    <section className="home" id="home">
      <div className="max-width">
        <div className="row">
          <div className="home-content">
            <div className="text-1">Hello,</div>
            <div className="text-2">Anil Ghatan</div>
            <div className="text-3">
              And I'm a <TypedText />
            </div>
            <a href="#contact" className="btn-purple">
              <span>Hire me</span>
            </a>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Hero;
