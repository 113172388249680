import { faLink } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import mixitup from "mixitup";

const Portfolio = () => {
  const styles = { display: "inline-block" };
  // var mixer = mixitup("#mix-container");
  return (
    <section id="works" className="work_section ">
      <div className="max-width">
        <h2 className="title">My Portfolio</h2>
        <div className="works-mix">
          <ul className="mix-nav">
            <li>
              <span className="filter" data-filter="all">
                All
              </span>
            </li>
            <li>
              <span className="filter" data-filter=".webdesign">
                Web Design
              </span>
            </li>
            <li>
              <span className="filter" data-filter=".wordpress">
                WordPress
              </span>
            </li>
            <li>
              <span className="filter" data-filter=".e-commerce">
                E-commerce
              </span>
            </li>
          </ul>
          <div id="mix-container" className="mix-container row">
            <div className="col-3 mix webdesign" style={styles}>
              <div className="work-mix">
                <div className="overlay">
                  <div className="links">
                    <a
                      href="https://www.omagroup.com/"
                      className="icon icn-link"
                    >
                      <FontAwesomeIcon icon={faLink} />
                    </a>
                  </div>
                  <div className="caption">
                    <h5>OMA Group</h5>
                    <p className="tags">Wordpress, Web Design, HTML</p>
                  </div>
                </div>
                <img src="images/oma-site.jpg" alt="OMA Group" />
              </div>
            </div>
            <div className="col-3 mix wordpress webdesign" style={styles}>
              <div className="work-mix">
                <div className="overlay">
                  <div className="links">
                    <a
                      href="https://www.jenoptik.co.uk/"
                      className="icon icn-link"
                    >
                      <FontAwesomeIcon icon={faLink} />
                    </a>
                  </div>
                  <div className="caption">
                    <h5>Jenoptik</h5>
                    <p className="tags">Wordpress, Web Design, HTML</p>
                  </div>
                </div>
                <img src="images/jenoptik.jpg" alt="jenoptik" />
              </div>
            </div>
            <div className="col-3 mix webdesign" style={styles}>
              <div className="work-mix">
                <div className="overlay">
                  <div className="links">
                    <a
                      href="https://www.jansonsproperty.com/"
                      className="icon icn-link"
                    >
                      <FontAwesomeIcon icon={faLink} />
                    </a>
                  </div>
                  <div className="caption">
                    <h5>Jansons Property</h5>
                    <p className="tags">Wordpress, Web Design, HTML</p>
                  </div>
                </div>
                <img src="images/jansons-site.jpg" alt="Jansons Property" />
              </div>
            </div>
            <div className="col-3 mix wordpress webdesign" style={styles}>
              <div className="work-mix">
                <div className="overlay">
                  <div className="links">
                    <a
                      href="https://www.ekohome.co.uk/"
                      className="icon icn-link"
                    >
                      <FontAwesomeIcon icon={faLink} />
                    </a>
                  </div>
                  <div className="caption">
                    <h5>Eko Home</h5>
                    <p className="tags">Wordpress, Web Design, HTML</p>
                  </div>
                </div>
                <img src="images/ekobin-site.jpg" alt="Eko Home" />
              </div>
            </div>
            <div className="col-3 mix e-commerce" style={styles}>
              <div className="work-mix">
                <div className="overlay">
                  <div className="links">
                    <a
                      href="https://wearecoffeepoint.co.uk/"
                      className="icon icn-link"
                    >
                      <FontAwesomeIcon icon={faLink} />
                    </a>
                  </div>
                  <div className="caption">
                    <h5>Coffee POint</h5>
                    <p className="tags">Wordpress, Web Design, HTML</p>
                  </div>
                </div>
                <img src="images/Coffee-point.jpg" alt="OMA Group" />
              </div>
            </div>
            <div className="col-3 mix e-commerce" style={styles}>
              <div className="work-mix">
                <div className="overlay">
                  <div className="links">
                    <a href="https://pointfuse.com/" className="icon icn-link">
                      <FontAwesomeIcon icon={faLink} />
                    </a>
                  </div>
                  <div className="caption">
                    <h5>Pointfuse</h5>
                    <p className="tags">Wordpress, Web Design, HTML</p>
                  </div>
                </div>
                <img src="images/point-fuse.jpg" alt="OMA Group" />
              </div>
            </div>
            <div className="col-3 mix wordpress webdesign" style={styles}>
              <div className="work-mix">
                <div className="overlay">
                  <div className="links">
                    <a
                      href="https://neroproject.co.uk/"
                      className="icon icn-link"
                    >
                      <FontAwesomeIcon icon={faLink} />
                    </a>
                  </div>
                  <div className="caption">
                    <h5>Nero Project</h5>
                    <p className="tags">Wordpress, Web Design, HTML</p>
                  </div>
                </div>
                <img src="images/nero-project.jpg" alt="OMA Group" />
              </div>
            </div>
            <div className="col-3 mix webdesign" style={styles}>
              <div className="work-mix">
                <div className="overlay">
                  <div className="links">
                    <a href="https://crisp.digital/" className="icon icn-link">
                      <FontAwesomeIcon icon={faLink} />
                    </a>
                  </div>
                  <div className="caption">
                    <h5>Crisp Digital</h5>
                    <p className="tags">Wordpress, Web Design, HTML</p>
                  </div>
                </div>
                <img src="images/crisp-digital.jpg" alt="OMA Group" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
export default Portfolio;
