import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
const Instagram = () => {
  return (
    <section className="Instagram_section">
      <div className="max-width">
        <h2 className="title">Instagram</h2>
        <OwlCarousel className=" gallery-list" loop margin={10}>
          <div className="gallery_item">
            <img src="images/gallery9.jpg" alt="Anil Ghatan" />
          </div>
          <div className="gallery_item">
            <img src="images/gallery10.jpg" alt="Anil Ghatan" />
          </div>
          <div className="gallery_item">
            <img src="images/gallery11.jpg" alt="Anil Ghatan" />
          </div>
          <div className="gallery_item">
            <img src="images/gallery12.jpg" alt="Anil Ghatan" />
          </div>
          <div className="gallery_item">
            <img src="images/gallery1.jpg" alt="Anil Ghatan" />
          </div>
          <div className="gallery_item">
            <img src="images/gallery2.jpg" alt="Anil Ghatan" />
          </div>
          <div className="gallery_item">
            <img src="images/gallery3.jpg" alt="Anil Ghatan" />
          </div>
          <div className="gallery_item">
            <img src="images/gallery4.jpg" alt="Anil Ghatan" />
          </div>
          <div className="gallery_item">
            <img src="images/gallery5.jpg" alt="Anil Ghatan" />
          </div>
          <div className="gallery_item">
            <img src="images/gallery6.jpg" alt="Anil Ghatan" />
          </div>
          <div className="gallery_item">
            <img src="images/gallery7.jpg" alt="Anil Ghatan" />
          </div>
        </OwlCarousel>
        <a
          href="https://www.instagram.com/anilghatan/"
          target="_blank"
          rel="noreferrer"
          className="btn-purple"
        >
          <span>Follow Me</span>
        </a>
      </div>
    </section>
  );
};
export default Instagram;
