import {
  faEnvelope,
  faMapMarkedAlt,
  faUser,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
const Contact = () => {
  return (
    <section className="contact" id="contact">
      <div className="max-width">
        <h2 className="title">Contact me</h2>
        <div className="contact-content">
          <div className="column left">
            <div className="text">Get in Touch</div>
            <p>
              I will always listen to and learn from various opinions. I will
              cherish any words you say.
            </p>
            <div className="icons">
              <div className="row">
                <FontAwesomeIcon icon={faUser} />
                <div className="info">
                  <div className="head">Name</div>
                  <div className="sub-title">Anil Ghatan</div>
                </div>
              </div>
              <div className="row">
                <FontAwesomeIcon icon={faMapMarkedAlt} />

                <div className="info">
                  <div className="head">Address</div>
                  <div className="sub-title">Kapan, Kathmandu Nepal</div>
                </div>
              </div>
              <div className="row">
                <FontAwesomeIcon icon={faEnvelope} />
                <div className="info">
                  <div className="head">Email</div>
                  <div className="sub-title">anilghatan01@gmail.com</div>
                </div>
              </div>
            </div>
          </div>
          <div className="column right">
            <div className="text">Do you have anything to say?</div>
            <form className="contact-form" action="#contact" method="POST">
              <div className="fields">
                <div className="field name">
                  <input type="text" className="fullname" placeholder="Name" />
                </div>
                <div className="field email">
                  <input
                    type="text"
                    className="email-input"
                    placeholder="Email"
                  />
                </div>
              </div>
              <div className="field">
                <input type="text" className="subject" placeholder="Subject" />
              </div>
              <div className="field textarea">
                <textarea
                  className="message"
                  cols="30"
                  rows="10"
                  placeholder="Message.."
                ></textarea>
              </div>
              <div className="button-area">
                <button
                  className="send-msg btn-purple"
                  type="submit"
                  name="send"
                >
                  <span>Send message</span>
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </section>
  );
};
export default Contact;
