import TypedText from "./components/TypedText";

const About = () => {
  return (
    <section className="about" id="about">
      <div className="max-width animated bounceInDown slow go">
        <h2 className="title">About me</h2>
        <div className="about-content">
          <div className="column left">
            <img src="images/profile-11.jpeg" alt="" />
          </div>
          <div className="column right">
            <div className="text">
              I'm Anil and I'm a <TypedText />
            </div>
            <p>
              Be yourself, free yourself and celebrate who you are’ is my
              mission and the reason why I joined the world of Information
              Techonology
            </p>
            <a href="#contact" className="btn-purple">
              <span>Download CV</span>
            </a>
          </div>
        </div>
      </div>
    </section>
  );
};
export default About;
